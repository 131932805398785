.grid {
  &.connected-cards {
    .card {
      &:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .card-body {
          padding-bottom: 0;

        }
        .alternative-color {
          margin-bottom: 0;
        }
      }
      &:nth-child(2) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: 0;
        border-right: 0;
      }
      &:nth-child(3) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 0;
      }
    }
  }
}
