header {
  display: flex;
  align-items: center;
  background: $white;

  h2 {
    color: $friendly-fig-500;
    font-size: 1.3125rem;
    font-weight: 400;
    margin: 0;
    margin-top: 0.2rem;
  }

  .brand-container {
    display: flex;
    align-items: center;
    height: 4.375rem;

    .logo-svg {
      height: 100%;
      width: 100%;
      max-width: 193.2px;
      max-height: 46px;
      min-width: 136px;
      display: inline-block;
      background: url($logo-path) no-repeat 0 0;
      background-position: center center;
      background-size: contain;
      text-indent: -100000px;
      @include media-breakpoint-up(sm) {
        min-width: 193.2px;
      }
    }
    .batch {
      display: inline-flex;
      padding: 0.094rem 0.313rem;
      text-transform: uppercase;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #005a8c;
      border-radius: $border-radius-sm;
      color: $white;
      font-size: 0.75rem;
      font-family: $font-family-base;
      font-weight: 600;
      line-height: 1.313rem;
      &:after {
        content: url($chevron-down);
        transform: scale(0.8);
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
        border: 0;
        vertical-align: 0;
        margin-left: 5px;
        margin-top: 0px;
      }
    }
  }
  .settings-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 4.375rem;
    .btn-outline-primary {
      padding: 0.3rem 1.525rem;
    }
    .btn-link {
      color: $gray-title;
      text-decoration: none;
      font-weight: 500;
    }
  }
  &.wrap {
    width: 100vw;
    padding: 0;
    margin: 0;
  }
}
