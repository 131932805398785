.rating-container {
  label {
    @include media-breakpoint-up(lg) {
      min-width: 10rem;
    }
    font-size: $font-size-sm;
    &:before {
      font-family: "Fontawesome solid";
      width: 18px;
      height: 18px;
      margin-right: 0.4rem;
    }
    &.control {
      &:before {
        content: "\f1fc";
      }
    }
    &.ease {
      margin-left: 1rem;
      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }
      &:before {
        content: "\f085";
      }
    }
  }

  .rating {
    filter: invert(78%) sepia(44%) saturate(919%) hue-rotate(347deg) brightness(104%) contrast(103%);
    font-family: "Fontawesome solid";
    &.stars-1 {
      &:after {
        content: "\f005";
      }
    }
    &.stars-2 {
      &:after {
        content: "\f005\f005";
      }
    }
    &.stars-3 {
      &:after {
        content: "\f005\f005\f005";
      }
    }
    &.stars-4 {
      &:after {
        content: "\f005\f005\f005\f005";
      }
    }
    &.stars-5 {
      &:after {
        content: "\f005a\f005a\f005a\f005a\f005a";
      }
    }
  }
}
