.badge {
	display: inline-block;
	background-color: $gray-highlight;
	color: $white;
	border: 0;
	vertical-align: middle;
	font-size: $font-size-base;
	font-family: $font-family-base;
	padding: 4px 8px;
	border-radius: $border-radius-sm;
	line-height: 1.4;
	&--success {
		background-color: $success-500;
    color: $white;
	}
	&--error,
	&--danger,
	&--failure {
		background-color: $error-500;
    color: $white;
	}
	&--pilot {
		background-color: $warning-500;
    &:after {
      font-family: "Fontawesome solid";
      content: "\f05a";
			display: inline-block;
    }
		&:hover {
			background-color: mix($white, $warning-500, 5%);
		}
	}
	&--grouped {
		background-color: $gray-highlight;
	}
	&--big {
		font-size: 1.1em;
	}
	td &,
	th & {
		margin-left: 16px;
	}
	.badge-info {
		cursor: pointer;
	}
}

// styles for editing in DotCMS
.dot-edit {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  .edit-mode {
    background: $primary !important;
    margin-right: 5px;
    padding: 8px;
    font-weight: bold;
    box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.33);
    -webkit-box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.33);
  }
}
.dot-edit-override {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .edit-mode {
    background: red !important;
    margin-right: 5px;
    font-weight: bold;
    padding: 8px;
    box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.33);
    -webkit-box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, 0.33);
  }
}
