.scroll-to-top {
  display: none;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  text-decoration: none !important;
  border-radius: 50%;
  bottom: 2rem;
  left: 86vw;
  width: 40px;
  height: 40px;
  background-color: $primary;
  box-shadow: 0px 3px 6px -2px #00000024;
  position: sticky;
  z-index: 9999;
  &:after {
    font-family: "Fontawesome solid";
    content: "\f078";
    display: inline-block;
    transform: rotate(180deg);
  }
  &:hover {
    background-color: mix($white, $primary, 30%);
  }
  &.show {
    display: flex;
    animation: showToTop 0.3s ease-in;
  }
  @include media-breakpoint-up(md) {
    left: 93vw;
  }
}

@keyframes showToTop {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(130%);
  }
  80% {
    transform: scale(70%);
  }
  100% {
    transform: scale(100%);
  }
}
