div.snippet-container {
  position: relative;
  pre {
    padding: 1em;
    border-radius: $border-radius-lg;
    background: $code-background !important;
  }

  code {
    &::selection {
      background-color: rgba($primary, 0.5) !important;
      color: $white !important;
    }
    span {
      &::selection {
        background-color: rgba($primary, 0.5) !important;
        color: $white !important;
      }
    }
  }

  // .copy-to-clipboard-button {
  //   position: absolute;
  //   top: -0.5rem;
  //   right: 0.5rem;
  //   font-family: $font-family-base;
  //   &:before {
  //     font-family: "Fontawesome regular";
  //     content: "\f0c5";
  //     color: $primary;
  //     display: inline-block;
  //     margin-right: 0.4rem;
  //   }
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     &:before {
  //       color: $white;
  //     }
  //   }
  //   &[data-copy-state="copy-success"] {
  //     &:before {
  //       font-family: "Fontawesome solid";
  //       content: "\f00c";
  //       color: $white;
  //     }
  //   }
  // }
}
