.page-item {
  .page-link {
    cursor: pointer;
    font-weight: 400;
  }
  &:first-child {
    .page-link {
      border-top-left-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
    }
  }
  &:last-child {
    .page-link {
      border-top-right-radius: $border-radius-sm;
      border-bottom-right-radius: $border-radius-sm;
    }
  }
}
