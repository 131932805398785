.breadcrumb {
  padding-top: 2.75rem;
  .breadcrumb-item {
    font-size: 0.75rem;
    a {
      font-weight: 400;
      font-size: 0.75rem;
    }
  }
}
