.footer-container {
  border-top: 1px solid $gray-light;
  padding: 2.8125rem 0;
  margin-top: 5rem;

  strong {
    color: $gray-title;
    display: inline-block;
  }
  nav {
    a {
      color: $gray-title;
      font-weight: 400;
    }
  }
  .bottom-links {
    a {
      color: $gray-body;
      font-weight: 400;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .social-media {
    a {
      height: 2.125rem;
      margin: 0 1rem;
      background-repeat: no-repeat;
      background-position: center center;
      &.linkedin {
        background-image: url($linkedin);
      }
      &.twitter {
        background-image: url($twitter);
      }
      &.facebook {
        background-image: url($facebook);
      }
      &.youtube {
        background-image: url($youtube);
      }
      &.instagram {
        background-image: url($instagram);
      }
      &:hover {
        opacity: 0.7;
      }
      &[target="_blank"] {
        &:after {
          display: none;
        }
      }
    }
  }
}
