.btn {
  border-radius: $border-radius-button-xl;
  padding: 0.3rem 1.525rem;
  &.btn-lg {
    border-radius: $border-radius-button-lg;
    padding: 0.5rem 3.225rem;
    font-weight: 700;
  }
  &:hover {
    text-decoration: none;
  }
}
.btn-primary {
  color: $white;
  &:hover {
    color: $white;
  }
}
.btn-default {
  padding: 0;
  border: 0;
  &:hover {
    color: $primary;
  }
}
.btn-link {
  padding: 0;
  font-size: $font-size-lg;
  border: 0;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  &:after {
    content: url($chevron-right);
    display: inline-block;
    position: absolute;
    top: 3px;
    transform: translateX(0);
    transition: all 0.2s;
    filter: $filter-color-primary;
  }
  &:hover {
    text-decoration: none;
    &:after {
      transform: translateX(5px) scale(1.2);
    }
  }
}
.btn-yellow {
  background-color: #ffdb0f;
  color: $gray-title;
  &:hover {
    background-color: mix($white, #ffdb0f, 30%);
  }
}
.btn-outline-primary {
  font-weight: 700;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  &.dropdown-toggle {
    &:after {
      filter: $filter-color-primary;
    }
    &:hover,
    &:active,
    &:focus {
      color: $white;
      &:after {
        filter: invert(96%) sepia(100%) saturate(0%) hue-rotate(129deg) brightness(104%) contrast(105%);
      }
    }
  }
  &:hover {
    color: $white;
  }
}
.btn-link {
  &.dropdown-toggle {
    &:after {
      transform: none;
      margin-left: 3px;
      margin-top: -5px;
      filter: invert(3%) sepia(3%) saturate(6476%) hue-rotate(214deg) brightness(93%) contrast(90%);
    }
    &:hover,
    &:active,
    &:focus {
      color: $primary;
      &:after {
        transform: none;
        filter: $filter-color-primary;
      }
    }
  }
}

.btn-search {
  background: none;
  border: 0;
  background-image: url($search);
  background-repeat: no-repeat;
  filter: $filter-color-primary;
  text-indent: -10000px;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.7;
  }
}

.btn-copy-not-formatted {
  background-color: $white;
  border-color: $primary;
  color: $primary;
  font-weight: 700;
  &:hover,
  &:active,
  &:focus {
    background-color: mix($white, $primary, 30%);
    color: $white;
  }
}

.btn-copy {
  background-color: $code-btn-background;
  border-color: $code-btn-border;
  color: $white;
  font-weight: 700;
  position: absolute;
  padding: 0.3rem 1rem;
  top: 1rem;
  right: 1rem;
  &:before {
    font-family: "Fontawesome regular";
    content: "\f0c5";
    color: $primary;
    display: inline-block;
    margin-right: 0.4rem;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: mix($white, $code-btn-background, 30%);
    color: $white;
  }
  &[data-copy-state="copy-success"] {
    &:before {
      font-family: "Fontawesome solid";
      content: "\f00c";
      color: $secondary;
    }
  }
}
