.property {
  line-height: 1.8;
  display: inline-block;
  padding: 0.1rem 0.5rem;
  font-size: $font-size-xs;
  background-color: $gray-row-bg;
  border: 1px solid $gray-borders;
  border-radius: $border-radius-sm;
  font-family: $font-family-code;
  word-break: break-word;
  cursor: default;
}
a {
  span.property {
    cursor: pointer;
  }
}
td:first-child .property + br + .property {
  margin-left: 1rem;
}
