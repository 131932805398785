// HEADER DESKTOP NAVIGATION
.nav-container {
  nav.desktop-nav {
    height: 3.75rem;
    background-color: $white;
    display: flex;
    align-items: center;
    ul.nav {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      li.nav-item {
        a.nav-link {
          font-size: $font-size-base;
          color: $gray-title;
          text-decoration: none;
          font-weight: 500;
          padding-left: 0;
          padding-right: 4rem;
          &:hover {
            color: mix($gray-background, $primary, 20%);
          }
          &.active {
            color: $primary;
            span:after {
              content: "";
              height: 2px;
              width: 100%;
              background-color: $primary;
              display: block;
              position: relative;
              bottom: -15px;
              left: 0;
            }
          }
        }
        .btn-link {
          font-size: $font-size-xl;
          color: $gray-title;
          text-decoration: none;
          font-weight: 500;
          margin-top: -5px;
          @include media-breakpoint-only(lg) {
            margin-top: -2px;
          }
          &.dropdown-toggle {
            &:after {
              margin-top: -2px;
              margin-left: 6px;
            }
          }
          &:hover,
          &:active,
          &:focus {
            color: $primary;
          }
        }
        .dropdown {
          margin-left: -3.2rem;
        }
      }
    }
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
}

// MOBILE NAVIGATION
.hamburger {
  cursor: pointer;
  &:after {
    filter: $filter-color-primary;
    content: url($hamburger);
    width: 18px;
    height: 18px;
    position: relative;
    top: 4px;
    margin-right: 1rem;
  }
  &:hover {
    color: $gray-title;
    &:after {
      filter: invert(4%) sepia(5%) saturate(2323%) hue-rotate(214deg) brightness(99%) contrast(92%);
    }
  }
}
.mobile-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20em;
  background: $white;
  z-index: 9999;
  left: -20em;
  transform: translate(0, 0);
  transition: transform 0.25s ease;
  .sub-nav {
    position: absolute;
    margin-top: -1px;
    top: 0;
    bottom: 0;
    width: 20em;
    background: $white;
    z-index: 9999;
    left: -20em;
    transform: translate(0, 0);
    transition: transform 0.25s ease;
  }
  .sub-nav-check {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  ul {
    line-height: 3em;
    text-decoration: none;
    padding: 0;
    list-style: none;
    li {
      a {
        font-size: $font-size-base;
        font-weight: 500;
        display: block;
        padding-left: 1.5em;
        color: $gray-title;
        text-decoration: none;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  .heading {
    color: $gray-subtext;
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    border-bottom: 1px solid $gray-borders;
    margin-bottom: 1rem;
    line-height: 3rem;
    padding-top: 0;
    position: relative;
    overflow: visible;
    cursor: pointer;
    &:after {
      filter: invert(4%) sepia(5%) saturate(2323%) hue-rotate(214deg) brightness(99%) contrast(92%);
      content: url($close);
      position: relative;
      right: -4px;
      top: 4px;
      width: 18px;
      height: 18px;
    }
    &:hover {
      color: $primary;
      &:after {
        filter: invert(19%) sepia(69%) saturate(4073%) hue-rotate(169deg) brightness(100%) contrast(101%);
      }
    }
  }
  .sub-heading {
    padding-left: 1.5em;
    color: $gray-subtext;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    padding: 1rem 0 1rem 1rem;
    border-bottom: 1px solid $gray-borders;
    margin-bottom: 1rem;
    &:before {
      content: url($chevron-left);
      filter: invert(54%) sepia(7%) saturate(533%) hue-rotate(202deg) brightness(102%) contrast(87%);
      position: relative;
      top: 4px;
      width: 18px;
      height: 18px;
    }
    &:hover {
      color: $primary;
      &:before {
        filter: invert(19%) sepia(69%) saturate(4073%) hue-rotate(169deg) brightness(100%) contrast(101%);
      }
    }
  }
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  label.toggle-sub {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: $font-size-base;
    font-weight: 500;
    padding-left: 1.5em;
    color: $gray-title;
    &:after {
      position: relative;
      top: 4px;
      right: 6px;
      content: url($chevron-right);
    }
    &:hover {
      color: $primary;
      &:after {
        filter: invert(19%) sepia(69%) saturate(4073%) hue-rotate(169deg) brightness(100%) contrast(101%);
      }
    }
  }
  .location-container {
    flex-grow: 2;
    h2 {
      color: $friendly-fig-500;
      font-size: 1.3125rem;
      font-weight: 400;
      margin: 0;
      margin-top: 0.2rem;
    }
    .batch {
      display: inline-flex;
      padding: 0.094rem 0.313rem;
      text-transform: uppercase;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #005a8c;
      border-radius: $border-radius-sm;
      color: $white;
      font-size: 0.75rem;
      font-family: $font-family-base;
      font-weight: 600;
      line-height: 1.313rem;
      &:after {
        content: url($chevron-down);
        transform: scale(0.8);
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
        border: 0;
        vertical-align: 0;
        margin-left: 5px;
        margin-top: 0px;
      }
    }
  }
}
#main-nav-check {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

#main-nav-check:checked ~ body,
#main-nav-check:checked ~ .mobile-nav,
.mobile-nav .sub-nav-check:checked ~ [id$="-sub"] /* matches all elements with id ending "-sub" */ {
  transform: translate(20em, 0);
}

.nav-bg {
  display: none;
  background-color: rgba(0, 0, 0, 0);
}

#main-nav-check:checked ~ .nav-bg {
  display: block;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9999;
  width: 100vw;
  bottom: 0;
  top: 0;
}

// NAV-ASIDE
aside {
  min-height: 60vh;
  border-right: 1px solid $gray-light;
  nav.nav-aside {
    position: sticky;
    top: 8rem;
    padding-top: 2.75rem;
    ul {
      list-style: none;
      &.main-level {
        margin: 0;
        padding: 0;
        li {
          a {
            color: $gray-title;
            text-transform: none;
            font-weight: 400;
          }
          a.main-item {
            font-style: italic;
            color: $gray-subtext;
            font-weight: 600;
            text-decoration: none;
            line-height: 3rem;
            &:hover,
            &:active,
            &:focus {
              color: $gray-title;
            }
            &.locked:after {
              font-style: normal;
              font-family: "Fontawesome solid";
              content: "\f023";
              color: $sure-sage-500;
              display: inline-block;
              margin-left: 0.5rem;
            }
          }
          &.active {
            a.main-item {
              color: $primary;
            }
          }
        }
      }

      &.second-level {
        margin-bottom: 2rem;
        padding: 0;
        padding-left: 1rem;
        li {
          padding: 0.25rem 0;
          &.active {
            a {
              color: $primary;
            }
          }
          ul.third-level {
            display: none;
            &.show {
              display: block;
            }
          }
          a.nav-dropdown {
            &:after {
              margin-left: 0.3rem;
              width: 12px;
              font-family: "Fontawesome solid";
              content: "\f078";
              color: $primary;
              display: inline-block;
              transition: all 0.2s;
            }
            &.open {
              &:after {
                transform: rotate(180deg);
                margin-left: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}

// SUBNAV ON PAGE
.in-page-sub-nav-container {
  position: sticky;
  top: 0px;
  z-index: 900;
  width: 100%;
  height: 3.75rem;
  .in-page-sub-nav {
    height: 3.75rem;
    max-height: 3.75rem;
    background-color: $white;
    border-radius: $border-radius-md;
    box-shadow: 0px 3px 6px -2px #00000024;
    position: sticky;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    ul.nav {
      display: flex;
      justify-content: space-between;
      flex-shrink: 0;
      li.nav-item {
        a.nav-link {
          cursor: pointer;
          font-size: $font-size-base;
          color: $gray-title;
          text-decoration: none;
          font-weight: 500;
          @include media-breakpoint-only(lg) {
            padding: 0.7rem;
          }
          &:hover {
            color: mix($gray-background, $primary, 20%);
          }
          &.active {
            color: $primary;
          }
        }
      }
      &.show {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 25rem;
          height: 3.75rem;
          background-image: linear-gradient(90deg, #ffffff00 14%, #ffffffff 100%);
          pointer-events: none;
        }
      }
    }

    &.remove-radius {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .overflown-toggle {
    display: none;
    position: absolute;
    top: 0;
    right: 2px;
    z-index: 901;
    .dropdown-toggle {
      height: 3.75rem;
      width: 70px;
      display: flex;
      justify-content: center;
      border-radius: 0;
      &:after {
        font-family: "Fontawesome solid";
        content: "\f142";
        display: inline-block;
        color: $primary;
        filter: none;
        margin-top: 15px;
        margin-left: 15px;
        font-size: $font-size-lg;
      }
      &:hover {
        &:after {
          color: $secondary;
        }
      }
    }
    &.show {
      display: block;
    }
  }
}
