.hero {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem 0;
  @include media-breakpoint-up(md) {
    margin: 0 0 6.25rem 0;
    flex-direction: row;
  }

  &--swapped {
    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
    }
  }

  &__text {
    flex-basis: 50%;
    flex-shrink: 1;

    &--small {
      p {
        font-size: 14px;
      }
    }

    &--large {
      p {
        font-size: 18px;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 2rem;
  }

  &__image {
    display: flex;
    justify-content: center;
    flex-grow: 0;

    img {
      flex-grow: 1;
      max-width: 100%;
      object-fit: contain;
      max-height: 400px;
    }

    &--rounded {
      img {
        margin: auto;
        border-radius: 16px;
      }
    }
  }
}
