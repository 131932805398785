fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: $white;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: $primary transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: a 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: a 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: $font-size-base;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  min-height: 35px;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: $gray-title;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  opacity: 0.6;
}
.multiselect--active {
  z-index: 1;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 0;
  background: $white;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__input:-ms-input-placeholder {
  color: $gray-subtext;
}
.multiselect__input::placeholder {
  color: $gray-subtext;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: $gray-borders;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: $gray-borders;
  outline: none;
}
.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 0;
  border: 1px solid #e8e8e8;
  background: $white;
  font-size: $font-size-base;
  &:before {
    font-family: "Fontawesome solid";
    content: "\f002";
    display: inline-block;
    color: $gray-subtext;
    position: relative;
    top: -3px;
  }
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: $border-radius-lg;
  margin-left: 10px;
  color: $primary;
  line-height: 1rem;
  font-weight: 600;
  border: 1px solid $primary;
  background-color: transparent;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  top: -4px;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 25px;
  transition: all 0.2s ease;
}
.multiselect__tag-icon:after {
  font-family: "Fontawesome solid";
  content: "\f00d";
  color: $primary;
  font-size: $font-size-base;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: mix($gray-background, $primary, 20%);
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  border-radius: 0;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 5px;
  color: $primary;
  margin-top: 4px;
  font-family: "Fontawesome solid";
  content: "\f078";
  font-size: 12px;
}
.multiselect__placeholder {
  color: $gray-subtext;
  display: inline-block;
  position: relative;
  top: -4px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: $white;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid $gray-borders;
  border-top: none;
  z-index: 1;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom: none;
  border-top: 1px solid $gray-borders;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  white-space: nowrap;
  font-size: $font-size-base;
  font-weight: 400;
  color: $gray-title;
  position: relative;
  padding-left: 30px;
  &:before {
    position: absolute;
    top: 13px;
    left: 8px;
    font-family: "Fontawesome regular";
    content: "\f0c8";
    display: inline-block;
    color: $gray-borders;
  }
}
.multiselect__option:after {
  display: none;
}
.multiselect__option--highlight {
  background: $gray-row-bg;
  outline: none;
  color: $primary;
  &:before {
    color: $primary;
  }
}
.multiselect__option--highlight:after {
  display: none;
}
.multiselect__option--selected {
  background: $gray-row-bg;
  color: $primary;
  font-weight: 600;
  &:before {
    position: absolute;
    top: 13px;
    left: 8px;
    font-family: "Fontawesome regular";
    content: "\f14a";
    display: inline-block;
    color: $primary;
  }
}
// .multiselect__option--selected:after {
//   content: attr(data-selected);
//   color: silver;
// }
// .multiselect__option--selected.multiselect__option--highlight {
//   background: #ff6a6a;
//   color: $white;
// }
// .multiselect__option--selected.multiselect__option--highlight:after {
//   background: #ff6a6a;
//   content: attr(data-deselect);
//   color: $white;
// }
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: none !important;
  color: $gray-subtext !important;
  cursor: text;
  pointer-events: none;
  border: 1px solid $gray-borders;
  border-right: 0;
  border-left: 0;
  margin-left: -20px;
  text-indent: 2px;
}
.multiselect__option--group {
  background: none;
  color: $gray-subtext;
  &:before {
    display: none;
  }
}
// .multiselect__option--group.multiselect__option--highlight {
//   background: #35495e;
//   color: $white;
// }
// .multiselect__option--group.multiselect__option--highlight:after {
//   background: #35495e;
// }
// .multiselect__option--disabled.multiselect__option--highlight {
//   background: #dedede;
// }
// .multiselect__option--group-selected.multiselect__option--highlight {
//   background: #ff6a6a;
//   color: $white;
// }
// .multiselect__option--group-selected.multiselect__option--highlight:after {
//   background: #ff6a6a;
//   content: attr(data-deselect);
//   color: $white;
// }
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
[dir="rtl"] .multiselect {
  text-align: right;
}
[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0 40px;
}
[dir="rtl"] .multiselect__content {
  text-align: right;
}
[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@keyframes a {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}

.multiselect__input {
  position: relative !important;
  top: 1px;
}
