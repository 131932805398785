form {
  max-width: 46.875rem;
  .form-control,
  .form-select {
    border-radius: $border-radius-md;
  }

  .input-group-text {
    border-top-left-radius: $border-radius-md !important;
    border-bottom-left-radius: $border-radius-md !important;
    min-width: 50px;
    background-color: $gray-light;
  }
}
.send-btn-container {
  position: relative;
  display: inline;
  &.spinner {
    &:after {
      font-family: "Fontawesome solid";
      content: "\f110";
      color: $white;
      animation: 2s linear 0s normal none infinite spin;
      display: inline-block;
      position: absolute;
      right: 24px;
      top: -2px;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
