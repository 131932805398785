@font-face {
  font-family: "Fontawesome regular";
  font-display: swap;
  font-style: normal;
  src: url("../fonts/fontawesome/fa-regular-400.woff2") format("woff2");
}

@font-face {
  font-family: "Fontawesome solid";
  font-display: swap;
  font-style: normal;
  src: url("../fonts/fontawesome/fa-solid-900.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-weight: 100;
  font-display: swap;
  font-style: normal;
  src: url("../fonts/worldline/Inter-Thin.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: 200;
  font-display: swap;
  font-style: normal;
  src: url("../fonts/worldline/Inter-ExtraLight.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url("../fonts/worldline/Inter-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url("../fonts/worldline/Inter-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: url("../fonts/worldline/Inter-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: url("../fonts/worldline/Inter-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-weight: 800;
  font-display: swap;
  font-style: normal;
  src: url("../fonts/worldline/Inter-ExtraBold.woff2") format("woff2");
}