.progress {
  height: 6px;
  box-shadow: none;
  margin-bottom: 0;
  position: relative;
  background-color: $gray-borders;
  color: $gray-body;
  border-radius:0;
  &:after {
      content: '';
      position: absolute;
      top: 0;
      left: calc(20% - 4px);
      width: calc(20% + 6px);
      height: 10px;
      border-left: 4px solid #fff;
      border-right: 4px solid #fff;
  }
  &:before {
      content: '';
      position: absolute;
      top: 0;
      right: calc(20% - 4px);
      width: calc(20% + 6px);
      height: 10px;
      border-left: 4px solid #fff;
      border-right: 4px solid #fff;
  }
  .progress-bar {
    background-color: $gray-body;
    
  }
}