svg {
  *[id^='step']{
    rect {
      fill: $step-color;
    }
  }
  #arrow_real_time {
    path {
      fill: $arrow-real-time-color;
    }
  }
  *[id^='badge_']{
    rect {
      fill: $svg-badge-color;
    }
    path {
      fill: $svg-badge-text-color;
    }
  }
  *[id^='badge_warning']{
    rect {
      fill: $svg-badge-warning-color;
    }
    path {
      fill: $svg-badge-warning-text-color;
    }
  }
}
