ul:has(li.checked) {
  list-style: none;
  li.checked {
    &:before {
      font-family: "Fontawesome solid";
      content: "\f00c";
      color: $secondary;
      margin-left: -25px;
      padding-right: 10px;
    }
  }
}

ul.checkedList {
  list-style: none;
  li {
    font-style: italic;
    &:before {
      font-family: "Fontawesome solid";
      content: "\f00c";
      color: $secondary;
      margin-left: -25px;
      padding-right: 10px;
    }
  }
}

ul.list-with-bullet {
  list-style: disc;
}
main {
  counter-reset: li-counter;
  ol {
    list-style: none;
    padding-left: 0;
    > li {
      font-size: $font-size-base;
      position: relative;
      padding-left: 2.25rem;
      padding-left: 1.5rem;
      counter-increment: li-counter;

      &:before {
        content: counter(li-counter);
        color: $primary;
        font-weight: 700;
        display: inline-block;
        width: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0.97rem;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  ol.inline-numbered-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem 2rem;
    justify-content: center;

    > li {
      padding-left: 0;
      padding-top: 3rem;
      width: 8rem;
      line-height: 1.5;

      p[style] {
        text-align: center !important;
      }

      &:before {
        font-size: 36px;
        top: -10px;
        left: 0;
        right: 0;
        width: 100%;
        transform: none;
      }
    }
  }

  ol + ol,
  ol + ul,
  ol + p,
  p + ol,
  h3 + ol,
  h4 + ol,
  h3 + img + ol,
  h4 + img + ol {
    counter-reset: li-counter 0;
  }
}

.list-group-item {
  img {
    margin-bottom: 0;
  }
}

ul.countries-currencies {
  li {
    font-size: $font-size-sm;
  }
}

ul li {
  &::marker {
    color: $primary;
    font-weight: 700;
    display: inline-block;
    width: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    left: 0;
    transform: translateY(-50%);
  }
}

li {
  p {
    margin: 0;
  }
}
