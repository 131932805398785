.alert {
  border-radius: $border-radius-lg;
  padding: 1.25rem 4.5rem;
  color: $gray-body;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 2rem;
    top: 1.35rem;
    display: flex;
    width: 1.5625rem;
    height: 1.5625rem;
    border-radius: 50%;
    background-color: $primary;
    align-items: center;
    justify-content: center;
    font-size: $font-size-sm;
  }

  &.alert-warning {
    background-color: $alert-warning;
    border-color: $alert-warning;
    &:before {
      font-family: "Fontawesome solid";
      content: "\21";
      color: $white;
      background-color: $warning-500;
    }
  }
  &.alert-danger {
    background-color: $error-100;
    border-color: $error-100;
    &:before {
      font-family: "Fontawesome solid";
      content: "\f0e7";
      color: $white;
      background-color: $error-500;
    }
  }
  &.alert-success {
    background-color: $success-100;
    border-color: $success-100;
    &:before {
      font-family: "Fontawesome solid";
      content: "\f164";
      color: $white;
      background-color: $success-500;
    }
  }
  &.alert-header {
    background-position: right;
    background-repeat: no-repeat !important;
    background-color: #D0DBD82E;
    background-image: url('../images/bg-card.svg') !important;
    border-radius: $border-radius-lg;
    font-size: $font-size-xl;
    font-weight: 300;
    line-height: 2.3625rem;
    padding: 2rem;
    &:before {
      display: none;
    }
  }
  &.alert-info {
    background-color: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), $alert-info;
    border-color: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), $alert-info;
    &:before {
      font-family: "Fontawesome solid";
      content: "\f129";
      color: $white;
      background-color: $info-300;
    }
    .btn {
      border-color: $info-400 !important;
      color: $info-400 !important;
      background-color: $white;
      &:hover,
      &:active,
      &:focus {
        background-color: $info-400;
        color: $white !important;
      }
    }
  }
  p {
    margin-bottom: 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }
}
