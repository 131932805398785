.dropdown-menu {
  border-radius: $border-radius-sm;
  border-color: $gray-borders;
  white-space: nowrap;
  a {
    font-size: $font-size-sm;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
    &:active,
    &:focus {
      color: $white;
    }
  }
}
.dropdown-toggle {
  &:after {
    content: url($chevron-down);
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    border: 0;
    vertical-align: 0;
    margin-left: 0px;
    margin-top: -3px;
  }
}

header, .mobile-nav {
  .dropdown-menu {
    min-width: 17.5rem;
    width: auto !important;
    max-width: 400px !important;
    &:before {
      font-family: "Fontawesome solid";
      font-weight: 900;
      font-size: 1.25rem;
      line-height: 1.25rem;
      content: "\f00d";
      position: absolute;
      right: 0.9rem;
      top: 0.8rem;
      color: $primary;
      cursor: pointer;
    }
    background: #fff;
    margin: -2.5rem 0 0 0 !important;
    padding: 1.5rem !important;
    border-radius: $border-radius-lg;
    border: 0;
    box-shadow: 0 0 1.4px rgba(0, 0, 0, 0.029), 0 0 3.8px rgba(0, 0, 0, 0.043), 0 0 7.7px rgba(0, 0, 0, 0.053),
      0 0 13.8px rgba(0, 0, 0, 0.064), 0 0 24.4px rgba(0, 0, 0, 0.083), 0 0 45px rgba(0, 0, 0, 0.14);

    a {
      font-size: $font-size-base;
      text-decoration: none;
      margin: 0.5rem 0;
      line-height: normal !important;
      padding-left: 0;
      cursor: pointer;
      &:hover {
        color: $primary;
      }
      &:active,
      &:focus {
        color: $white;
      }
    }
    .dropdown-header {
      color: $gray-subtext;
      font-size: $font-size-sm !important;
      padding: 0;
      margin-bottom: 0.25rem;
      font-weight: 500;
    }
  }
}
