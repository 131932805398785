//------------- LOGO
$logo-path: './__brands/smartpos/logo.svg';

//------------- COLORS
//primary
$primary: #007b7c;
$secondary: #46beaa;

$filter-color-primary: invert(23%) sepia(74%) saturate(3523%) hue-rotate(167deg) brightness(89%) contrast(101%);

// Secondary
$sure-sage-500: #2d8c8c;
$sure-sage-100: #e5f2f4;
$bold-blueberry-500: #41b4d2;
$bold-blueberry-100: #d0fbf8;
$friendly-fig-500: #005a8c;
$friendly-fig-100: #7ad0ff;
$reliable-rhubarb-500: #f08791;
$reliable-rhubarb-100: #fce6e8;
$confident-corn-500: #ffeb78;
$confident-corn-100: #fffbe0;

//code snippets
$code-background: #00193a;
$code-btn-background: #00334f;
$code-btn-border: #00466c;
$code-icon: #009ff4;

// Neutrals
$black: #000000;
$gray-title: #141317;
$gray-body: #575665;
$gray-subtext: #8b8b9a;
$gray-highlight: #bfbdc8;
$gray-borders: #e2e1e6;
$gray-light: #ebebf1;
$gray-background: #fbfbfb;
$gray-row-bg: #fafafa;
$white: #ffffff;
$input: #c8c8cb;
$branding-bg: #efefef;

// Sementic
$success-500: #008556;
$success-100: #eef7f4;
$warning-500: #fe6c30;
$warning-100: #feecd4;
$error-500: #d81b2f;
$error-100: #ffeae4;
$info-500: #005a8c;
$info-400: #1585b0;
$info-300: #3fb5d5;
$info-100: #e9fffd;

$alert-warning: #fffaf4;
$alert-info: #7edef0;

//------------- FONT
$font-family-base: Inter, "Myriad Pro", Myriad, Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-headings: Inter, "Myriad Pro", Myriad, Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-code: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
$font-family-headings-color: $gray-title;

$font-size-base: 1rem; // 16px
$font-size-xs: $font-size-base * 0.775; // 12px
$font-size-sm: $font-size-base * 0.875; // 14px
$font-size-lg: $font-size-base * 1.125; // 18px
$font-size-xl: $font-size-base * 1.3125; // 21px

//------------- BORDER-RADIUS
$border-radius: 1.1875rem;
$border-radius-sm: 0.1875rem;
$border-radius-md: 0.375rem;
$border-radius-lg: 0.75rem;
$border-radius-xl: 6.25rem;

$border-radius-button-lg: 3.25rem;
$border-radius-button-xl: 6.25rem;

//------------- DARK-MODE
$dark-mode-bg-color: #212026;
$dark-mode-dark-color: #3c3b45;
$dark-mode-darker-color: #141317;
$dark-mode-text-color: #f5f6fa;
$dark-mode-heading-color: #ebebf1;
$dark-mode-menu-link-color: #bfbdc8;

//------------- TABLES
$table-border-color: $gray-borders;
$table-hover-bg: $gray-row-bg;
$table-striped-bg: $gray-row-bg;

//------------- ACCORDION
$accordion-padding-y: 1rem;
$accordion-padding-x: 1rem;
$accordion-bg: $gray-row-bg;
$accordion-border-color: $gray-borders;
$accordion-border-radius: $border-radius-md;
$accordion-inner-border-radius: $border-radius-md;
$accordion-icon-color: $dark-mode-text-color !important;
$accordion-icon-active-color: red !important;

$enable-cssgrid: true;

$grid-columns: 24;
$grid-gutter-width: 1rem;
$grid-row-columns: 12;

//------------- SVG
$step-color: $primary;
$arrow-real-time-color: $primary;
$svg-badge-color: #E5F2F4;
$svg-badge-warning-color: #FDE6F1;
$svg-badge-warning-text-color: #D6006B;
$svg-badge-text-color: #006A7A;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 7.5,
  9: $spacer * 9,
  10: $spacer * 10.5
);
