.card {
  word-wrap: break-word;
  background-clip: border-box;
  background-color: #fff;
  border: 0.0625rem solid $gray-light;
  display: flex;
  position: relative;
  height: 100%;
  padding: 1rem;
  overflow: hidden;
  h6 {
    color: $primary;
  }
  &.card-link {
    cursor: pointer;
    transition: transform 100ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover {
      color: #818094;
      box-shadow: 0px 0.7px 0.9px hsl(240deg 7% 79% / 0.11), 0px 2.3px 2.9px -0.5px hsl(240deg 7% 79% / 0.14),
        0px 5px 6.4px -1px hsl(240deg 7% 79% / 0.18), 0px 11.1px 14.2px -1.5px hsl(240deg 7% 79% / 0.21);
    }
  }
  h5 {
    font-weight: 600;
  }
  .card-content,
  &.card-content {
    display: flex;
    a {
      font-weight: 500;
      text-decoration: none !important;
    }
  }
  .card-title {
    margin-top: 0 !important;
  }
  .case-card-image {
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

.bg-gradient {
  background-position: 99%;
  background-repeat: no-repeat !important;
  background-image: url("../images/bg-card.svg") !important;
  background-size: cover;
}
.bg-gradient {
  &.reversed {
    background-position: 95%;
  }
}
