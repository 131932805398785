html {
  scrollbar-gutter: stable;

  body {
    background: $white;
    background-attachment: fixed;
    background-repeat: no-repeat !important;
    background-image: url('../images/bg.svg');
    &.in-search {
      overflow: hidden;
    }
    .container {
      position: relative;
    }
    main {
      min-height: 60vh;
    }
  }
}
