// offsetting anchors
a[id]::before {
  content: "";
  display: block;
  height: 75px;
  margin-top: -75px;
  visibility: hidden;
}

li a[id]::before {
  display: none;
}