html.dark-theme {
  // Onetrust Cookie banner
  #onetrust-consent-sdk #onetrust-banner-sdk,
  #onetrust-consent-sdk #onetrust-pc-sdk {
    background-color: $dark-mode-dark-color !important;
    font-family: $font-family-base !important;
    font-size: $font-size-base !important;
    color: $dark-mode-text-color !important;
    #onetrust-policy-text {
      color: $dark-mode-text-color;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $dark-mode-heading-color !important;
    }
    p {
      color: $dark-mode-text-color !important;
    }
  }

  #onetrust-consent-sdk #onetrust-pc-sdk .category-menu-switch-handler {
    background-color: $dark-mode-darker-color !important;
  }
  #onetrust-consent-sdk #onetrust-pc-sdk .ot-active-menu {
    background-color: $dark-mode-dark-color !important;
  }
  #onetrust-pc-sdk .ot-pc-footer-logo {
    background-color: $dark-mode-dark-color !important;
  }

  body {
    background-color: $dark-mode-bg-color !important;
    color: $dark-mode-text-color;

    ::selection {
      color: $white;
      background: rgba($primary, 0.6);
    }

    main img {
      filter: brightness(0.8) contrast(1.2);
    }

    * {
      border-color: $dark-mode-dark-color !important;
    }

    header {
      background: $dark-mode-bg-color !important;
    }

    nav.desktop-nav {
      background-color: $dark-mode-bg-color !important;
      a.nav-link {
        color: $dark-mode-text-color !important;
        &:hover {
          color: $primary !important;
        }
      }
    }

    // type
    ul {
      color: $dark-mode-text-color;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $dark-mode-heading-color;
    }

    //nav
    .mobile-nav {
      background: $dark-mode-darker-color;
      .heading {
        &:after {
          filter: invert(95%) sepia(8%) saturate(1288%) hue-rotate(188deg) brightness(106%) contrast(96%);
        }
      }
      ul li a {
        color: $dark-mode-text-color;
      }
      label.toggle-sub {
        color: $dark-mode-text-color;
        &:after {
          filter: invert(95%) sepia(8%) saturate(1288%) hue-rotate(188deg) brightness(106%) contrast(96%);
        }
      }
      .sub-nav {
        background: $dark-mode-darker-color;
      }
    }
    .in-page-sub-nav {
      background: $dark-mode-darker-color;
      ul.nav {
        li.nav-item {
          a.nav-link {
            color: $dark-mode-text-color !important;
            &:hover {
              color: $primary !important;
            }
          }
        }
        &.show {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 25rem;
            height: 3.75rem;
            background-image: linear-gradient(90deg, #ffffff00 14%, $dark-mode-darker-color 100%);
            pointer-events: none;
          }
        }
      }
    }
    .language-nav {
      background: $dark-mode-dark-color;
      .nav-item {
        label {
          color: $dark-mode-text-color;
        }
        .nav-link {
          color: $dark-mode-text-color !important;
          background-color: mix($white, $dark-mode-darker-color, 10%);
          &.active {
            color: $primary !important;
            border-color: $primary !important;
          }
          &:hover {
            border-color: $primary !important;
          }
        }
      }
    }

    nav.nav-aside ul li {
      a {
        color: $dark-mode-text-color;
      }
    }

    //code
    :not(pre) > code[class*="language-"],
    pre[class*="language-"] {
      background: mix($black, $code-background, 60%);
    }

    //rating
    .rating {
      filter: none;
      color: $confident-corn-500;
    }

    //buttons
    .btn-link {
      &.dropdown-toggle {
        color: $white;
        &:after {
          filter: invert(100%) sepia(11%) saturate(19%) hue-rotate(113deg) brightness(99%) contrast(110%);
        }
      }
    }
    .btn-default {
      color: $dark-mode-text-color;
      &:hover {
        color: $primary;
      }
    }
    .btn-outline-primary {
      &:hover,
      &:active,
      &:focus {
        border-color: $primary !important;
      }
    }
    .btn-search {
      filter: invert(100%) sepia(0%) saturate(7495%) hue-rotate(330deg) brightness(106%) contrast(100%);
    }
    .btn-outline-primary {
      color: $white;
      border-color: $white !important;
      &.dropdown-toggle {
        &:after {
          filter: invert(100%) sepia(0%) saturate(7495%) hue-rotate(330deg) brightness(106%) contrast(100%);
        }
        &:hover,
        &:active,
        &:focus {
          border-color: $primary !important;
          &:after {
            filter: invert(100%) sepia(0%) saturate(7495%) hue-rotate(330deg) brightness(106%) contrast(100%);
          }
        }
      }
    }
    //content
    .white-content-section,
    .white-section {
      background-color: $dark-mode-darker-color !important;
      background-image: none;
      color: $dark-mode-text-color;
    }

    .numbers {
      color: $dark-mode-text-color;
    }

    //footer {
    .footer-container {
      background-color: $dark-mode-bg-color;
      strong {
        color: $dark-mode-text-color;
      }
      nav {
        a {
          color: $dark-mode-text-color;
        }
      }
      .bottom-links {
        a {
          color: $dark-mode-text-color;
        }
      }
    }

    //accordion
    .accordion-item {
      background-color: $dark-mode-dark-color;
      color: $dark-mode-text-color;
      .accordion-button {
        background-color: $dark-mode-darker-color;
        color: $dark-mode-text-color;
      }
    }

    //tables
    table {
      th {
        color: $dark-mode-text-color;
      }
      thead {
        th,
        td {
          color: $dark-mode-text-color;
          a {
            color: $dark-mode-text-color;
          }
        }
      }
      tbody {
        td {
          color: $dark-mode-text-color;
        }
      }
    }

    table.table-hover > tbody > tr:hover {
      --bs-table-hover-bg: #3c3b45;
      --bs-table-hover-color: #f5f6fa;
    }

    //property
    .property {
      background-color: $dark-mode-dark-color;
      color: $gray-light;
    }

    //alerts
    .alert {
      color: $dark-mode-text-color;
      position: relative;
      background: $dark-mode-dark-color;
    }

    //cards
    .card {
      background-color: $dark-mode-darker-color;
      color: $dark-mode-text-color;
      .card-body {
        &.alternative-color,
        .alternative-color {
          background-color: $dark-mode-dark-color;
          li.checked {
            color: $dark-mode-text-color;
          }
        }
      }
    }

    //progress
    .progress {
      color: $primary;
      &:after {
        border-left: 4px solid $dark-mode-darker-color;
        border-right: 4px solid $dark-mode-darker-color;
      }
      &:before {
        border-left: 4px solid $dark-mode-darker-color;
        border-right: 4px solid $dark-mode-darker-color;
      }
      .progress-bar {
        background-color: $primary;
      }
    }

    //multiselect
    .multiselect__tags {
      background: $dark-mode-darker-color;
      &:before {
        color: $dark-mode-text-color;
      }
    }
    .multiselect__content-wrapper {
      background: $dark-mode-darker-color;
    }
    .multiselect__input {
      background: $dark-mode-darker-color;
      color: $dark-mode-text-color;
    }
    .multiselect__option {
      color: $dark-mode-text-color;
      &:before {
        color: $dark-mode-text-color;
      }
    }
    .multiselect__option--highlight {
      background: $dark-mode-dark-color;
    }
    .multiselect__option--selected {
      background: $dark-mode-dark-color;
      color: $dark-mode-text-color;
    }
  }

  //dropdown
  .dropdown-menu {
    background: $dark-mode-darker-color;
    .dropdown-item {
      color: $dark-mode-text-color;
      &:hover {
        background-color: $dark-mode-dark-color;
      }
    }
  }

  //search
  .overlay-search {
    background-color: rgba(0, 0, 0, 0.6);
  }

  #search-results-component {
    h1 {
      span {
        color: $primary;
      }
    }
    .list-group {
      background-color: $dark-mode-darker-color;
      .list-group-item {
        background-color: $dark-mode-darker-color;
        color: $dark-mode-text-color;
        a {
          b {
            background-color: rgba($primary, 0.2);
            padding: 0.2rem 0.5rem;
          }
          h3 {
            margin-top: 0.75rem;
            font-size: $font-size-xl;
            color: $primary;
          }
          p {
            color: $dark-mode-text-color;
          }
        }
      }
    }

    .page-item {
      .page-link {
        background-color: $dark-mode-dark-color;
        color: $dark-mode-text-color;
      }
      &.active {
        .page-link {
          background-color: $primary;
          color: $dark-mode-text-color;
        }
      }
    }
  }

  //forms
  .form-control,
  .form-select {
    color: $dark-mode-text-color;
    background-color: $dark-mode-dark-color;
  }
  .filepond--drop-label {
    color: $dark-mode-text-color !important;
  }

  .filepond--panel-root {
    color: $dark-mode-text-color !important;
    background-color: $dark-mode-dark-color !important;
  }

  //accordions
  .accordion-body {
    background-color: $dark-mode-darker-color;
  }

  pre[class*="language-"] > code {
    background-color: rgba($code-background, 0.5);
  }
}
