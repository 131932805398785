::selection {
  color: $gray-body;
  background: rgba($primary, 0.2);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headings;
  color: $font-family-headings-color;
}
h1 {
  font-weight: 800;
  font-size: 3rem;
  margin-bottom: 2rem;
}
h2 {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 0.75rem;
}
h3 {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 0.75rem;
  &.numbered {
    font-size: 1.5rem;
    font-weight: 700;
    span {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: inline-flex;
      background-color: $primary;
      color: $white;
      font-weight: 700;
      align-items: center;
      justify-content: center;
      font-size: $font-size-base;
      margin-right: 0.625rem;
      position: relative;
      top: -4px;
      counter-increment: h3-counter 1;

      &:before {
        content: counter(h3-counter);
      }
    }
  }
  &.not-numbered {
    font-size: 1.25rem;
    font-weight: 600;
  }
}
h4 {
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
}
h5 {
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
h6 {
  font-weight: 700;
  font-size: 1.3125rem;
  margin-bottom: 0.1875rem;
  color: $gray-body;
}

body {
  font-family: $font-family-base;
  font-weight: 400;
  color: $gray-body;
  font-size: $font-size-base;
  line-height: 1.875rem;
}

hr {
  opacity: 1;
  border-top: 1px solid $gray-borders;
  &.numbered {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
}

.integration {
  hr:last-child {
    display: none;
  }
}

strong {
  font-family: $font-family-base;
  font-weight: 700;
}
a {
  font-weight: 700;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &[target="_blank"] {
    &:after {
      font-family: "Fontawesome solid";
      content: "\f08e";
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
  &.arrow-right {
    &:after {
      font-family: "Fontawesome solid";
      content: "\f054";
      display: inline-block;
      font-size: $font-size-sm;
      margin-left: 0.2rem;
    }
  }
  &.badge {
    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
  &.link-secondary {
    color: $primary !important;
  }
  &.caret {
    &:after {
      content: url($icn-arrow);
      margin-left: 0.5rem;
    }
  }
}

*[data-href] {
  cursor: pointer;
}

main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2.5rem 0 1rem 0;
    scroll-margin-top: 135px;
  }
}
