.white-section {
  background: $white url(../images/bottom-border.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  border-radius: $border-radius-lg;
  padding: 2.125rem;
  @include media-breakpoint-up(lg) {
    padding: 3.125rem 7.19rem;
  }
}
.white-content-section {
  border-radius: $border-radius-lg;
  background: $white;
  padding: 1.2rem;
  border: 1px solid $gray-borders;
  counter-reset: h3-counter 0;
  @include media-breakpoint-up(lg) {
    padding: 1.875rem;
  }
  
}
.transparent-section {
  @include media-breakpoint-up(lg) {
    padding: 3.125rem 0;
  }
}
