label.optimized-for {
  color: $gray-title;
  font-weight: 700;
  display: flex;
  span {
    font-style: italic;
    font-weight: 400;
    margin-left: 3rem;
  }
}
