//custom variables
@import "./settings/variables";

//bootstrap
@import "./settings/bootstrap";

//settings
@import "./settings/functions"; 
@import "./settings/icons";
@import "./settings/fonts";
@import "./settings/type";
@import "./settings/code-theme";

//components
@import "./components/buttons";
@import "./components/badge";
@import "./components/switch";
@import "./components/nav";
@import "./components/cards";
@import "./components/dropdowns";
@import "./components/sections";
@import "./components/pagination";
@import "./components/breadcrumb";
@import "./components/rating";
@import "./components/scroll-to-top";
@import "./components/lists";
@import "./components/alert";
@import "./components/optimized-for";
@import "./components/multiselect";
@import "./components/table";
@import "./components/progress";
@import "./components/property";
@import "./components/accordion";
@import "./components/svg";
@import "./components/upload";
@import "./components/form";
@import "./components/anchor";
@import "./components/popover";

@import "../../node_modules/highlight.js/styles/atom-one-dark.css";

//layout
@import "./layout/header";
@import "./layout/footer";
@import "./layout/homepage";
@import "./layout/body";
@import "./layout/grid";
@import "./layout/code";

//darkmode
@import "./settings/darkmode";