.toggle-mode {
  display: none;
}
.switch {
  width: 40px;
  height: 24px;
  background: $gray-borders;
  border-radius: $border-radius-lg;
  position: relative;
  cursor: pointer;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 20px;
    width: 20px;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: inline-svg('<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z" stroke="' + $primary + '" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M7 2V1" stroke="' + $primary + '" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M7 13V12" stroke="' + $primary + '" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 7H13" stroke="' + $primary + '" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M1 7H2" stroke="' + $primary + '" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 10L11 11" stroke="' + $primary + '" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3 3L4 4" stroke="' + $primary + '" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 4L11 3" stroke="' + $primary + '" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3 11L4 10" stroke="' + $primary + '" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>');
    border-radius: 100%;
    transition: 0.1s;
  }
}

.toggle-mode:checked ~ .switch {
  background: $gray-body;
}
.toggle-mode:checked ~ .switch:after {
  transform: translateX(16px);
  background-color: $white;
  background-repeat: no-repeat;
  background-position: center center;
  top: 2px;
  right: 2px;
  background-position-y: 4px;
  background-image: inline-svg('<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.75 5.5C0.75 2.61719 3.09375 0.25 5.97656 0.25C6.25781 0.25 6.67969 0.296875 6.9375 0.34375C7.17188 0.390625 7.21875 0.695312 7.00781 0.8125C5.74219 1.53906 4.94531 2.89844 4.94531 4.375C4.94531 6.95312 7.26562 8.89844 9.82031 8.42969C10.0547 8.38281 10.1953 8.64062 10.0547 8.82812C9.07031 10.0234 7.59375 10.75 5.97656 10.75C3.09375 10.75 0.75 8.40625 0.75 5.5Z" fill="' + $primary + '"/> </svg>');
}
