.table-responsive:not(.payment-products) {
  border: 1px solid $gray-borders;
  border-radius: $border-radius-lg;
  margin-bottom: 1rem;
  table {
    font-size: $font-size-sm;
    margin-bottom: 0;
    thead {
      th,
      td {
        font-weight: 500;
        padding: 0.5rem 1.5rem 0.5rem 0.5rem;
        a {
          color: $gray-title;
          font-weight: 500;
          text-decoration: none;
        }
        p {
          margin: 0;
        }
      }
      td:has(+ span.property) {
        max-width: 12.5rem;
      }
    }
    tbody {
      td {
        vertical-align: top;
        border-left: 1px solid $gray-borders;
        &:first-child {
          border-left: none;
        }
        ul,
        ol {
          padding-left: 1rem;
        }
      }
      tr:last-child {
        td {
          border-bottom: 0;
        }
      }
      td,th {
        p {
          margin: 0;
        }
      }
    }
  }
}
table.payment-products {
  font-size: $font-size-base;
  thead {
    th {
      border-bottom: 0;
      a {
        color: $gray-title;
        font-weight: 500;
        text-decoration: none;
      }
    }
  }
  tbody {
    td {
      vertical-align: middle;
      border-right: 0;
      white-space: nowrap;
      &.product-name {
        font-weight: 700;
      }
      img.payment-product-logo {
        margin-bottom: 0;
        max-width: 80px;
      }
    }
  }
  &.table-bordered {
    border-radius: $border-radius-lg;
  }
}
